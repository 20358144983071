<template>
  <div>
    <v-container v-if="!languageTarget">
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            v-slot="{ errors }"
            name="Minimum"
            rules="numeric"
          >
            <v-text-field
              v-model="minimum"
              :error-messages="errors"
              hint="Minimum Qualification Age of a Participant"
              label="Minimum"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="6">
          <validation-provider
            v-slot="{ errors }"
            name="Maximum"
            rules="numeric"
          >
            <v-text-field
              v-model="maximum"
              :error-messages="errors"
              hint="Maximum Qualification Age of a Participant"
              label="Maximum"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { extend, ValidationProvider } from "vee-validate";
import { numeric } from "vee-validate/dist/rules";

extend("numeric", { ...numeric, message: "Must be a positive whole number" });

export default {
  name: "BirthdateOptions",
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    oFormFieldTypeId: {
      type: String,
      required: true,
    },
    languageTarget: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    minimum: {
      get() {
        return this.value?.minimum ?? null;
      },
      set(value) {
        this.$emit("input", {
          // Validation requires that this value be numeric inorder to save
          // leverage this so that when a value is provided and it is a number, we parse
          // it as an integer. Verify that a value is given in order to parseInt (e.g. we don't
          // want '' to be parsed as 0).
          // NOTE: while we check for "value" inorder to parse, a string of '0' is truthy,
          // so the user can input a value of 0 and everything will work as expected
          minimum: value && !isNaN(value) ? parseInt(value) : value,
          maximum: this.maximum,
        });
      },
    },
    maximum: {
      get() {
        return this.value?.maximum ?? null;
      },
      set(value) {
        this.$emit("input", {
          minimum: this.minimum,
          // Validation requires that this value be numeric inorder to save
          // leverage this so that when a value is provided and it is a number, we parse
          // it as an integer. Verify that a value is given in order to parseInt (e.g. we don't
          // want '' to be parsed as 0).
          // NOTE: while we check for "value" inorder to parse, a string of '0' still has a value,
          // so the user can input a value of 0 and everything will work as expected
          maximum: value && !isNaN(value) ? parseInt(value) : value,
        });
      },
    },
  },
};
</script>
