<template>
  <v-container fluid v-if="page">
    <v-card class="mx-auto">
      <v-card-title>
        <template v-if="!isTitleChange">
          {{ pageTitle }}
          <v-btn small color="primary" @click="manageTitleChange" class="ml-2">
            <v-icon aria-label="Change Page Title"> mdi-pencil </v-icon>
            <span class="d-none normal-font" aria-hidden="true"
              >Change Page Title</span
            >
          </v-btn>
        </template>
        <template v-else>
          <validation-observer ref="pageTitleObserver">
            <validation-provider
              v-slot="{ errors }"
              name="label"
              rules="required|max:255"
            >
              <v-text-field
                ref="pageTitle"
                v-model="pageTitle"
                :error-messages="errors"
                counter
                maxlength="255"
                hint="The title of the Page"
                label="Page Title"
                aria-required="true"
                @blur="manageTitleChange"
              ></v-text-field>
            </validation-provider>
          </validation-observer>
        </template>
        <v-btn
          v-if="canDeletePage"
          :loading="isEditingSurvey"
          small
          color="error"
          @click="deletePage"
          class="ml-2"
        >
          <v-icon aria-label="Delete Page"> mdi-delete </v-icon>
          <span class="d-none normal-font" aria-hidden="true">Delete Page</span>
        </v-btn>
        <template v-if="allowAddQuestion">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="addQuestion()"
            class="my-5"
            :loading="isEditingSurvey"
          >
            <v-icon aria-label="Add Question"> mdi-plus </v-icon>
            Add Question
          </v-btn>
        </template>
      </v-card-title>
      <v-card-text>
        <confirm-dialog ref="confirmDelete"></confirm-dialog>
        <question
          v-for="questionId in pageQuestionIds"
          :key="'survey-question-' + questionId"
          :id="questionId"
          class="item"
          @deleteQuestion="deleteQuestion(questionId)"
        >
        </question>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Question from "@admin/components/surveys/Question.vue";
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Page",
  components: {
    Question,
    ConfirmDialog,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    identity: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      /**
       * Since we need to get all questions that are related to the given page via a Vuex getter
       * that doesn't have its results cached (e.g. the getter is a function), we need to force the getter to
       * rerun manually. This requires managing a check that we review in the computed property the getter is used
       * that we can increment when we want the getter to rerun
       */
      questionIdCheck: 0,
      // Determine if the title of the page is in a state of allowing it to change
      isTitleChange: false,
    };
  },
  computed: {
    ...mapGetters("surveys", [
      "surveyPage",
      "surveyPageQuestionIdsPerPage",
      "isEditingSurvey",
    ]),
    page: {
      get() {
        return this.surveyPage(this.identity);
      },
    },
    pageTitle: {
      get() {
        return this.page ? this.page.title : "";
      },
      set(value) {
        this.changeSurveyPage({
          identity: this.identity,
          data: { title: value },
        });
      },
    },
    canDeletePage() {
      // Only allow pages that aren't of type acceptance_container to be deleted.
      // We want to ensure an admin can't remove an acceptance container from the survey
      // since it is required for a user to interact with this type of page
      return this.page
        ? this.page.oFormContainerTypeId !== "acceptance_container"
        : false;
    },
    pageQuestionIds() {
      this.questionIdCheck;
      return this.surveyPageQuestionIdsPerPage(this.identity);
    },
    allowAddQuestion() {
      return this.page.oFormContainerTypeId !== "acceptance_container";
    },
  },
  methods: {
    ...mapActions("surveys", {
      changeSurveyPage: "changePage",
      addSurveyPageQuestion: "addPageQuestion",
      deleteSurveyPageQuestion: "deletePageQuestion",
    }),
    deletePage() {
      // Don't allow for deleting a page if the survey is currently being edited
      if (this.isEditingSurvey) return;

      this.$emit("deleteSurveyPage", this.identity);
    },
    addQuestion() {
      // Don't allow for adding a question if the survey is being edited
      if (this.isEditingSurvey) return;
      this.addSurveyPageQuestion(this.identity).then(([success]) => {
        if (success) {
          this.questionIdCheck++;
          //update parent so lanugages can add the new question
          this.$emit("updateSurvey");
        }
      });
    },
    deleteQuestion(questionId) {
      this.$refs.confirmDelete
        .open("Confirm", "Are you sure you want to delete this question?")
        .then((confirm) => {
          if (confirm) {
            this.deleteSurveyPageQuestion(questionId).then(([success]) => {
              if (success) {
                this.questionIdCheck++;
              }
            });
          }
        });
    },
    manageTitleChange() {
      if (this.isTitleChange) {
        // When title change is true, the observer for the title field will be included
        // Check the observer on if validation has passed. If not, we don't want to transition
        // to title change not being true since we want to force the user to provide a valid title
        // before survey data can be changed
        this.$refs.pageTitleObserver.validate().then((success) => {
          if (success) {
            this.isTitleChange = false;
          }
        });
      } else {
        this.isTitleChange = true;
        this.$nextTick(() => {
          // Auto focus the title of the page when a user inititates a title change
          this.$refs.pageTitle.$refs.input.focus();
        });
      }
    },
  },
};
</script>

<style scoped></style>
