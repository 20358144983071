<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="languageTarget.text + ' Label'"
          rules="required|max:255"
        >
          <p>Default Label: {{ optionLabel }}</p>
          <v-text-field
            outlined
            v-model="secondaryOptionLabel"
            :error-messages="errors"
            counter
            maxlength="255"
            :hint="'The ' + languageTarget.text + ' Label'"
            :label="languageTarget.text + ' Option Label'"
            required
            aria-required="true"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import { required } from "vee-validate/dist/rules.js";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "QuestionOption",
  components: { ValidationProvider },
  props: {
    value: {
      type: Object,
      required: true,
    },
    // Determine if we want to allow for this option to allow for setting transitions
    allowTransitions: {
      type: Boolean,
      required: false,
      default: true,
    },
    languageTarget: {
      type: Object,
      required: true,
    },
  },
  watch: {
    allowTransitions(currentAllowance, oldAllowance) {
      // If allowing transitions was originally set to true, which means a transition could've been
      // set, and is now false, don't allow transitions to be set. We don't want to store transitions
      // for options that don't allow them
      if (oldAllowance && !currentAllowance) {
        this.optionTransition = null;
      }
    },
    surveyPagesList() {
      if (
        this.optionTransition &&
        !this.surveyPagesList.some(
          (page) => page.value === this.optionTransition
        )
      ) {
        // If the available page list changes, and the current selected transition is no longer
        // in the page list, set the transition target to null
        this.optionTransition = null;
      }
    },
  },
  computed: {
    ...mapGetters("systemLanguages", ["languagesList"]),
    isLessThanMediumBreakpoint() {
      return this.$vuetify.breakpoint.sm;
    },
    optionLabel() {
      return this.value.label;
    },
    secondaryOptionLabel: {
      get() {
        if (this.value && this.value[this.languageTarget.value + "Label"]) {
          return this.value[this.languageTarget.value + "Label"];
        }

        return "";
      },
      set(value) {
        this.$emit("input", {
          label: this.optionLabel,
          [this.languageTarget.value + "Label"]: value,
          ...this.getUnfocusedLanguageLabel(),
          value: this.optionValue,
          transition: this.optionTransition,
          qualifier: this.optionQualifier,
        });
      },
    },
    optionValue() {
      return this.value.value;
    },
    optionTransition() {
      return Object.hasOwn(this.value, "transition")
        ? this.value.transition
        : null;
    },
    optionQualifier() {
      return this.value.qualifier;
    },
  },
  methods: {
    getUnfocusedLanguageLabel() {
      const languageLabels = {};

      if (this.value) {
        for (const target of this.languagesList) {
          if (
            target.value !== this.languageTarget.value &&
            this.value[target.value + "Label"]
          ) {
            languageLabels[target.value + "Label"] =
              this.value[target.value + "Label"];
          }
        }
      }

      return languageLabels;
    },
  },
};
</script>

<style scoped></style>
