<template>
  <v-container fluid v-if="question">
    <v-card class="mx-auto">
      <v-card-title>
        <span>
          {{ question.title ? question.title : "Question Text" }}
        </span>
        <template v-if="allowDelete">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteQuestion"
            color="error"
            :loading="isEditingSurvey"
          >
            <v-icon aria-label="Delete Question"> mdi-delete </v-icon>
            Delete Question
          </v-btn>
        </template>
      </v-card-title>
      <v-card-text>
        <question-form v-model="question" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QuestionForm from "@admin/components/surveys/QuestionForm.vue";

export default {
  name: "Question",
  components: {
    QuestionForm,
  },
  props: {
    id: {
      required: true,
    },
  },
  computed: {
    ...mapGetters("surveys", ["surveyPageQuestion", "isEditingSurvey"]),
    question: {
      get() {
        // Get the question to be seen. Note, technically a question can't be guaranteed to exist on the survey
        // page, so we should only render content for this component if a question is actually found
        return this.surveyPageQuestion(this.id);
      },
      set(value) {
        this.changeSurveyPageQuestion({ id: this.id, data: value });
      },
    },
    // Determine if a question should be allowed to be deleted
    allowDelete() {
      return this.question.oFormFieldTypeId !== "acceptance-criteria";
    },
  },
  methods: {
    ...mapActions("surveys", {
      changeSurveyPageQuestion: "changePageQuestion",
    }),
    deleteQuestion() {
      // Don't allow for deleting a question if the survey is being edited
      if (this.isEditingSurvey) return;

      this.$emit("deleteQuestion");
    },
  },
};
</script>
