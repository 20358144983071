<template>
  <v-container fluid>
    <v-card-text>
      <h3 class="mb-3">Scenario 1: Survey is not active.</h3>
      <v-row>
        <v-col cols="12">
          <validation-provider name="Not Active Contact Form" rules="required">
            <label :class="{ 'error--text': !notActiveContactForm.length }"
              >Contact Form Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="notActiveContactForm"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="Not active consent message"
            rules="required"
          >
            <label :class="{ 'error--text': !notActiveConsentMessage.length }"
              >Consent Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="notActiveConsentMessage"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <h3 class="mb-3">Scenario 2: Not qualified.</h3>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="Not qualified contact form message"
            rules="required"
          >
            <label :class="{ 'error--text': !notQualifiedContactForm.length }"
              >Contact Form Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="notQualifiedContactForm"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="Not qualified consent message"
            rules="required"
          >
            <label
              :class="{ 'error--text': !notQualifiedConsentMessage.length }"
              >Consent Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="notQualifiedConsentMessage"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <h3 class="mb-3">Scenario 3: No trial sites.</h3>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="No trial sites contact form message"
            rules="required"
          >
            <label :class="{ 'error--text': !noTrialSitesContactForm.length }"
              >Contact Form Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="noTrialSitesContactForm"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="No trial sites consent message"
            rules="required"
          >
            <label
              :class="{ 'error--text': !noTrialSitesConsentMessage.length }"
              >Consent Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="noTrialSitesConsentMessage"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <h3 class="mb-3">Scenario 4: Trial site selected</h3>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="Trial site selected contact form message"
            rules="required"
          >
            <label
              :class="{ 'error--text': !trialSiteSelectedContactForm.length }"
              >Contact Form Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="trialSiteSelectedContactForm"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="Trial site selected consent message"
            rules="required"
          >
            <label
              :class="{
                'error--text': !trialSiteSelectedConsentMessage.length,
              }"
              >Consent Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="trialSiteSelectedConsentMessage"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <h3 class="mb-3">Scenario 5: Completed</h3>
      <v-row>
        <v-col cols="12">
          <validation-provider
            name="Completed contact form message"
            rules="required"
          >
            <label :class="{ 'error--text': !completedContactForm.length }"
              >Contact Form Message</label
            >
            <div class="wysiwyg-border">
              <fieldset>
                <wysiwyg
                  v-model="completedContactForm"
                  required
                  aria-required="true"
                />
              </fieldset>
            </div>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { ValidationProvider } from "vee-validate";

export default {
  name: "Messages",
  components: {
    ValidationProvider,
  },
  props: {
    identity: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      /**
       * Since we need to get all questions that are related to the given page via a Vuex getter
       * that doesn't have its results cached (e.g. the getter is a function), we need to force the getter to
       * rerun manually. This requires managing a check that we review in the computed property the getter is used
       * that we can increment when we want the getter to rerun
       */
      questionIdCheck: 0,
      // Determine if the title of the page is in a state of allowing it to change
      isTitleChange: false,
    };
  },
  computed: {
    ...mapGetters("surveys", {
      surveyPage: "surveyPage",
      getSurveyPageIdentities: "surveyPageIdentities",
      getSurveyPageQuestionsPerPage: "surveyPageQuestionsPerPage",
      secondaryLanguageTarget: "secondaryLanguageTarget",
    }),
    ...mapFields("surveys", {
      messages: "surveyMessages",
    }),
    notActiveContactForm: {
      get() {
        return this.messages.en_not_active_contact_form.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_not_active_contact_form: Object.assign(
            {},
            this.messages.en_not_active_contact_form,
            {
              message: value,
            }
          ),
        });
      },
    },
    notActiveConsentMessage: {
      get() {
        return this.messages.en_not_active_consent.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_not_active_consent: Object.assign(
            {},
            this.messages.en_not_active_consent,
            {
              message: value,
            }
          ),
        });
      },
    },
    notQualifiedContactForm: {
      get() {
        return this.messages.en_not_qualified_contact_form.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_not_qualified_contact_form: Object.assign(
            {},
            this.messages.en_not_qualified_contact_form,
            {
              message: value,
            }
          ),
        });
      },
    },
    notQualifiedConsentMessage: {
      get() {
        return this.messages.en_not_qualified_consent.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_not_qualified_consent: Object.assign(
            {},
            this.messages.en_not_qualified_consent,
            {
              message: value,
            }
          ),
        });
      },
    },
    noTrialSitesContactForm: {
      get() {
        return this.messages.en_no_trial_sites_contact_form.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_no_trial_sites_contact_form: Object.assign(
            {},
            this.messages.en_no_trial_sites_contact_form,
            {
              message: value,
            }
          ),
        });
      },
    },
    noTrialSitesConsentMessage: {
      get() {
        return this.messages.en_no_trial_sites_consent.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_no_trial_sites_consent: Object.assign(
            {},
            this.messages.en_no_trial_sites_consent,
            {
              message: value,
            }
          ),
        });
      },
    },
    trialSiteSelectedContactForm: {
      get() {
        return this.messages.en_trial_site_selected_contact_form.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_trial_site_selected_contact_form: Object.assign(
            {},
            this.messages.en_trial_site_selected_contact_form,
            {
              message: value,
            }
          ),
        });
      },
    },
    trialSiteSelectedConsentMessage: {
      get() {
        return this.messages.en_trial_site_selected_consent.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_trial_site_selected_consent: Object.assign(
            {},
            this.messages.en_trial_site_selected_consent,
            {
              message: value,
            }
          ),
        });
      },
    },
    completedContactForm: {
      get() {
        return this.messages.en_completed_contact_form.message;
      },
      set(value) {
        this.appendSurveyMessages({
          en_completed_contact_form: Object.assign(
            {},
            this.messages.en_completed_contact_form,
            {
              message: value,
            }
          ),
        });
      },
    },
  },
  methods: {
    // Provide a means to be able to change the current state of survey messages
    // based on however newMessageDetails indicates. This is left intentionally ambigious
    // so any amount of message details can be changed
    appendSurveyMessages(newMessageDetails) {
      this.messages = Object.assign({}, this.messages, newMessageDetails);
    },
    getUnfocusedLanguageMessages(messageType) {
      const languageTitles = {};

      if (this.value && this.value.configuration) {
        for (const target of this.languageTargets) {
          languageTitles[target.value + messageType] =
            this.value.configuration[target.value + messageType];
        }
      }

      return languageTitles;
    },
  },
};
</script>

<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
