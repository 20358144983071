<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ surveyError }}
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <confirm-dialog ref="confirmDelete"></confirm-dialog>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-container fluid>
            <v-card class="mx-auto mb-12">
              <section-header
                :isCollapsable="false"
                :target="() => self.$refs.surveyContainer"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" class="mt-3"> Survey </v-col>
                    <v-spacer></v-spacer>
                    <v-select
                      :items="secondaryLanguageOptions"
                      id="id"
                      item-text="text"
                      label="Additional Languages"
                      v-model="surveyAdditionalLanguages"
                      attach
                      chips
                      multiple
                      return-object
                      @change="updateSelectedSecondaryLanguages"
                    >
                    </v-select>
                  </v-row>
                </v-container>
              </section-header>
              <v-container fluid ref="surveyContainer">
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Title"
                      rules="required|max:255"
                    >
                      <v-text-field
                        v-model="title"
                        counter
                        maxlength="255"
                        hint="The title of the Survey"
                        label="Title"
                        prepend-icon="mdi-account"
                        required
                        aria-required="true"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-card class="mx-auto my-12">
              <section-header
                :isCollapsable="false"
                :target="() => self.$refs.componentsContainer"
                ><v-container>
                  <v-row>
                    <v-col cols="12" sm="4" class="mt-4">Components</v-col>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="addPage()"
                      class="my-5"
                      :loading="isEditingSurvey"
                    >
                      <v-icon aria-label="Add Page"> mdi-plus </v-icon>
                      Add Page
                    </v-btn>
                  </v-row>
                </v-container>
              </section-header>
              <v-container fluid ref="componentsContainer">
                <v-tabs
                  background-color="primary"
                  center-active
                  dark
                  show-arrows
                >
                  <v-tabs-slider color="white"></v-tabs-slider>
                  <draggable
                    v-model="tabs"
                    class="v-slide-group__wrapper"
                    :move="checkTabMove"
                  >
                    <v-tab
                      v-for="(identity, index) in surveyPageIdentities"
                      :key="index"
                      @change="tabChanged()"
                      >{{ surveyPage(identity).title }}
                      <v-icon
                        color="red"
                        class="d-none"
                        :ref="'tab-icon-' + identity"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </v-tab>
                    <v-tab key="messages" @change="tabChanged()">
                      Messages
                      <v-icon
                        class="d-none"
                        color="red"
                        ref="tab-icon-messages"
                      >
                        mdi-alert-circle-outline
                      </v-icon></v-tab
                    >
                  </draggable>
                  <v-tab-item
                    v-for="(identity, index) in surveyPageIdentities"
                    :key="index"
                    eager
                  >
                    <validation-observer :ref="'observer-' + identity">
                      <v-container fluid>
                        <v-tabs vertical v-model="activeLanguageTab">
                          <v-tab
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(0)"
                            >English
                            <v-icon
                              color="red"
                              class="d-none"
                              :ref="'tab-icon-' + identity + '-english'"
                            >
                              mdi-alert-circle-outline
                            </v-icon></v-tab
                          >
                          <v-tab
                            v-for="(
                              languageOption, index
                            ) in surveyAdditionalLanguages"
                            :key="languageOption.id"
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(index + 1)"
                            >{{ languageOption.text }}
                            <v-icon
                              color="red"
                              class="d-none"
                              :ref="
                                'tab-icon-' + identity + '-' + languageOption.id
                              "
                            >
                              mdi-alert-circle-outline
                            </v-icon></v-tab
                          >
                          <v-tab-item
                            eager
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="'observer-' + identity + '-english'"
                            >
                              <page
                                :key="identity"
                                :ref="'page-' + identity"
                                :identity="identity"
                                class="item"
                                @deleteSurveyPage="deletePage($event)"
                                @updateSurvey="updateSurvey"
                              >
                              </page>
                            </validation-observer>
                          </v-tab-item>
                          <v-tab-item
                            eager
                            v-for="languageOption in surveyAdditionalLanguages"
                            :key="languageOption.id"
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="
                                'observer-' + identity + '-' + languageOption.id
                              "
                            >
                              <secondary-page
                                :key="identity + '-' + languageOption.id"
                                :ref="
                                  'secondaryPage-' +
                                  identity +
                                  '-' +
                                  languageOption.id
                                "
                                :identity="identity"
                                :languageTarget="languageOption"
                                :questionUpdated="questionUpdated"
                                class="item"
                              >
                              </secondary-page>
                            </validation-observer>
                          </v-tab-item>
                        </v-tabs>
                      </v-container>
                    </validation-observer>
                  </v-tab-item>

                  <v-tab-item eager>
                    <validation-observer ref="observer-messages">
                      <v-container fluid>
                        <v-tabs vertical v-model="activeLanguageTab">
                          <v-tab
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(0)"
                            >English
                            <v-icon
                              color="red"
                              class="d-none"
                              :ref="'tab-icon-messages-english'"
                            >
                              mdi-alert-circle-outline
                            </v-icon></v-tab
                          >
                          <v-tab
                            v-for="(
                              languageOption, index
                            ) in surveyAdditionalLanguages"
                            :key="languageOption.id"
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(index + 1)"
                            >{{ languageOption.text }}
                            <v-icon
                              color="red"
                              class="d-none"
                              :ref="'tab-icon-messages-' + languageOption.id"
                            >
                              mdi-alert-circle-outline
                            </v-icon></v-tab
                          >
                          <v-tab-item
                            eager
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="'observer-messages-english'"
                            >
                              <messages
                                identity="messages"
                                class="item"
                              ></messages>
                            </validation-observer>
                          </v-tab-item>
                          <v-tab-item
                            eager
                            v-for="languageOption in surveyAdditionalLanguages"
                            :key="languageOption.id"
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="'observer-messages-' + languageOption.id"
                            >
                              <secondary-messages
                                identity="messages"
                                class="item"
                                :languageTarget="languageOption"
                              ></secondary-messages>
                            </validation-observer>
                          </v-tab-item>
                        </v-tabs>
                      </v-container>
                    </validation-observer>
                  </v-tab-item>
                </v-tabs>
              </v-container>
            </v-card>
          </v-container>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import Page from "@admin/components/surveys/Page.vue";
import SecondaryPage from "@admin/components/surveys/SecondaryPage.vue";
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";
import SectionHeader from "@admin/components/SectionHeader.vue";
import Messages from "@admin/components/surveys/Messages.vue";
import SecondaryMessages from "@admin/components/surveys/SecondaryMessages.vue";
import draggable from "vuedraggable";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "Form",
  components: {
    SectionHeader,
    ValidationObserver,
    ValidationProvider,
    Page,
    SecondaryPage,
    ConfirmDialog,
    Messages,
    SecondaryMessages,
    draggable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      snackbar: false,
      self: this,
      activeLanguageTab: 0,
      questionUpdated: 0,
    };
  },
  computed: {
    ...mapState("surveys", { surveyError: "error", survey: "survey" }),
    ...mapFields("surveys", {
      title: "survey.title",
      messages: "surveyMessages",
      surveyAdditionalLanguages: "surveyAdditionalLanguages",
    }),
    ...mapGetters("surveys", {
      surveyPage: "surveyPage",
      getSurveyPageIdentities: "surveyPageIdentities",
      getSurveyPageQuestionsPerPage: "surveyPageQuestionsPerPage",
      isEditingSurvey: "isEditingSurvey",
    }),
    ...mapGetters("systemLanguages", {
      secondaryLanguageOptions: "languagesList",
    }),

    surveyPageIdentities: {
      get() {
        return this.getSurveyPageIdentities;
      },
      set(value) {
        this.changeSurveyPageOrder(value);
      },
    },
    tabs: {
      get() {
        let allTabs = [...this.getSurveyPageIdentities];
        allTabs.push("messages");
        return allTabs;
      },
      set(value) {
        this.changeSurveyPageOrder(value);
      },
    },
    cancelButtonRoute() {
      return this.redirectRoute;
    },
    redirectRoute() {
      return {
        name: "SurveysView",
        params: { identity: this.survey.identity },
      };
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("surveys", {
      addSurveyPage: "addPage",
      clearSurvey: "clearSurvey",
      clearSurveyMessages: "clearSurveyMessages",
      editSurvey: "editSurvey",
      persistSurveyMessages: "persistSurveyMessages",
      getSurvey: "getSurvey",
      getSurveyMessages: "getMessages",
      changeSurveyPageOrder: "changePageOrder",
      deleteSurveyPage: "deletePage",
      toggleSurveyAdditionalLanguage: "toggleSurveyAdditionalLanguage",
    }),
    ...mapActions("oFormFieldTypes", {
      getOFormFieldTypes: "getOFormFieldTypes",
      refreshOFormFieldTypes: "refreshState",
    }),
    ...mapActions("oFormFieldCategories", {
      getOFormFieldCategories: "getOFormFieldCategories",
      refreshOFormFieldCategories: "refreshState",
    }),
    ...mapActions("tags", {
      getTags: "getTags",
      refreshTags: "refreshState",
    }),
    ...mapActions("systemLanguages", {
      getLanguagesList: "getLanguagesList",
    }),
    addPage() {
      // Don't allow for adding a new page while a survey is being edited
      if (this.isEditingSurvey) return;
      this.addSurveyPage();
    },
    deletePage(identity) {
      let confirmMessageQuestions = "";
      for (const question of this.getSurveyPageQuestionsPerPage(identity)) {
        confirmMessageQuestions += "<li>" + question.title + "</li>";
      }
      if (confirmMessageQuestions.length > 0) {
        confirmMessageQuestions = "<ul>" + confirmMessageQuestions + "</ul>";
      }

      this.$refs.confirmDelete
        .open(
          "Confirm",
          "<div>" +
            "<p>Are you sure you want to delete this page?</p><p>This will also delete the following questions:</p>" +
            confirmMessageQuestions +
            "</div>"
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteSurveyPage(identity);
          }
        });
    },
    updateActiveLanguageTab(index) {
      this.activeLanguageTab = index;
    },
    checkTabMove(e) {
      //don't allow drop if eleemnt id messages or if trying to drop after messages. If attempted then will move to last in order
      let isValidMove = !(
        e.draggedContext.element == "messages" ||
        e.relatedContext.element == "messages"
      );

      return isValidMove;
    },
    updateTabOrder() {},
    tabChanged() {
      this.updateAllTabIcons();
    },
    updateAllTabIcons() {
      let self = this;
      //get all identities
      const indentities = [...this.surveyPageIdentities];
      //add messages tab to identities list
      indentities.push("messages");

      let newIdentities = [...indentities];
      //add any additional languages identities
      if (this.surveyAdditionalLanguages.length) {
        for (let i in indentities) {
          for (let j in this.surveyAdditionalLanguages) {
            newIdentities.push(
              indentities[i] + "-" + this.surveyAdditionalLanguages[j].id
            );
          }
          //add default english
          newIdentities.push(indentities[i] + "-english");
        }
      }

      //for each identity, validate and show validation icon if invalid
      newIdentities.forEach(function (identity) {
        let pageObserver = null;
        if (self.$refs["observer-" + identity][0] !== undefined) {
          pageObserver = self.$refs["observer-" + identity][0];
        } else if (self.$refs["observer-" + identity] !== undefined) {
          pageObserver = self.$refs["observer-" + identity];
        }

        if (pageObserver !== null) {
          let tabIcons = self.$refs["tab-icon-" + identity];

          if (!Array.isArray(tabIcons)) {
            tabIcons = [tabIcons];
          }

          pageObserver.validate().then((isValid) => {
            for (let i in tabIcons) {
              if (tabIcons[i] !== undefined) {
                if (!isValid) {
                  tabIcons[i].$el.classList.remove("d-none");
                } else {
                  tabIcons[i].$el.classList.add("d-none");
                }
              }
            }
          });
        }
      });
    },
    async updateSelectedSecondaryLanguages() {
      await this.toggleSurveyAdditionalLanguage(this.surveyAdditionalLanguages);
      this.updateAllTabIcons();
    },
    updateSurvey() {
      this.questionUpdated++;
    },
    async submit() {
      //update all tab validation icons
      this.updateAllTabIcons();

      // Don't allow for submitting if the submission process was already initiated or the current contextual
      // survey is already indicated to be saved (a local submission is tracked in addition to isEditingSurvey
      // to account for restricting this process while any logic that needs processing is completed
      // after a survey is finished being edited)

      if (
        this.isSubmitting ||
        this.isEditingSurvey ||
        !(await this.$refs.observer.validate())
      ) {
        console.log("INVALID FIELDS");
        return;
      }

      this.isSubmitting = true;

      const promises = [];

      promises.push(this.persistSurveyMessages());
      promises.push(this.editSurvey());

      return Promise.all(promises).then((responses) => {
        const [messagesSuccess] = responses.shift();
        const [surveySuccess] = responses.shift();

        if (messagesSuccess && surveySuccess) {
          // Identify what the redirect route should be before clearing survey
          // so we know where to go next
          const redirect = this.redirectRoute;
          this.clearSurvey(); // force re-pull from db

          this.$router.push(redirect);
        } else {
          this.snackbar = true;
        }
        this.isSubmitting = false;
      });
    },
  },
  async beforeMount() {
    this.isLoading = true;

    const promises = [];

    promises.push(
      this.getSurvey(this.id).then(([success]) => {
        if (!success) {
          this.$router.push({ name: "NotFound" });
        }
        this.setTitleObject(this.survey.title);
        this.setEngagementBreadcrumb({
          id: this.survey.engagementId,
          name: this.survey.engagementName,
        });
        this.setSponsorBreadcrumb({
          id: this.survey.sponsorId,
          name: this.survey.sponsorName,
        });
        this.setBreadcrumbs({
          self: {
            id: this.survey.identity,
          },
        });
      })
    );

    promises.push(this.getLanguagesList());
    promises.push(this.getOFormFieldTypes());
    promises.push(this.getOFormFieldCategories());
    promises.push(this.getTags());

    await Promise.all(promises);

    // Once a survey is loaded, the following promises can be handled
    const followupPromises = [];

    followupPromises.push(this.getSurveyMessages());

    await Promise.all(followupPromises);
    this.setActionButtons([
      {
        component: "CancelButton",
        attributes: {
          to: "/surveys/view/" + this.survey.identity,
        },
        index: 0,
      },
      {
        component: "SaveButton",
        attributes: {
          onSubmit: () => this.submit(),
        },
        index: 1,
      },
    ]);
    this.isLoading = false;
  },
  updated() {
    //after mounted load any validation icons
    this.updateAllTabIcons();
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearSurvey();
    this.clearSurveyMessages();
    this.refreshOFormFieldTypes();
    this.refreshOFormFieldCategories();
    this.refreshTags();
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
</style>
