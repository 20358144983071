<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="8">
          <validation-provider
            v-slot="{ errors }"
            :name="languageTarget.text + ' Question Title'"
            rules="required|max:255"
          >
            <v-text-field
              outlined
              v-model="secondaryTitle"
              :error-messages="errors"
              counter
              maxlength="255"
              :hint="'The ' + languageTarget.text + ' Question'"
              :label="languageTarget.text + ' Question Text'"
              required
              aria-required="true"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-container>
    <component
      v-if="questionConfiguration"
      :is="questionConfiguration"
      v-model="configuration"
      :languageTarget="languageTarget"
      :oFormFieldTypeId="oFormFieldTypeId"
    ></component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { extend, ValidationProvider } from "vee-validate";
import { max, required } from "vee-validate/dist/rules.js";
import SecondaryQuestionOptions from "@admin/components/surveys/SecondaryQuestionOptions.vue";
import SecondaryQuestionAcceptanceCriteria from "@admin/components/surveys/SecondaryQuestionAcceptanceCriteria.vue";
import BirthdateOptions from "@admin/components/surveys/BirthdateOptions";

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "SecondaryQuestionForm",
  data() {
    return {
      tagsSearch: "",
      isAddingTag: false,
      // IMPORTANT: representing tags in this scope is based on computing a property that uses a function getter from vuex.
      // When this is done, reactivity doesn't automatically happen which means the value of tags will not be updated and
      // reflected to the combobox of tags. This is bad since this will mean any newly generated tags will not auto be
      // reflected in the combobox value. In order to make this happen, we need the following incrementing value that is
      // incremented when a tag is added and referenced in the computed property for tags to force reactivity and consequently
      // cause the recomputation of which tags are selected
      tagsIncrement: 0,
    };
  },
  props: {
    questionID: {
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    languageTarget: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    SecondaryQuestionOptions,
    SecondaryQuestionAcceptanceCriteria,
    BirthdateOptions,
  },
  watch: {
    questionConfiguration(newValue, oldValue) {
      if (newValue !== oldValue) {
        // If the nested component we'll render to represent question configuration
        // isn't the same as what was previously being loaded, reset configuration.
        // This is done since we expect to provide different configuration options, and we don't
        // want incompatible configuration options that may have been previously stored to be persisted
        // IMPORTANT: there are configuration options managed in this scope (i.e. not via nested components)
        // that should be persisted. The setter for configuration should be written in a way that keeps values for
        // these options while dropping everything else
        this.configuration = {};
      }
    },
  },
  computed: {
    ...mapGetters("tags", ["getTagsList"]),
    ...mapGetters("oFormFieldTypes", {
      questionTypes: "getFieldTypesList",
    }),
    ...mapGetters("oFormFieldCategories", {
      questionCategories: "getFieldCategoriesList",
    }),
    ...mapGetters("surveys", ["tagsPerQuestion"]),
    ...mapGetters("systemLanguages", ["languagesList"]),
    /**
     * Determine which component should be used to manage the configuration options of a question. The identified
     * value for such a component must be equal to the name of an imported component
     */
    questionConfiguration() {
      switch (this.value.oFormFieldTypeId) {
        case "multi-select":
        case "radio":
        case "select":
          return "SecondaryQuestionOptions";
        case "acceptance-criteria":
          return "SecondaryQuestionAcceptanceCriteria";
        case "birthdate":
          return "BirthdateOptions";
        default:
          return null;
      }
    },
    /**
     * Determine if a question should be allowed to have its type changed (which will change how the question operates).
     * We don't want to allow some question types to be changable since we want to force the user to keep the question type
     */
    allowTypeChange() {
      // Don't allow for changing the type of a question if it is acceptance criteria. We want
      // the question with this type to be "locked in"
      return this.oFormFieldTypeId !== "acceptance-criteria";
    },
    /**
     * Determine if a question should be allowed to have its category changed. We don't want to allow some types of
     * fields to have a category, so we want to prevent it being changed
     */
    allowCategoryChange() {
      // Don't allow for changing the category of a question if it is acceptance criteria. Technically
      // this type of question shouldn't be allowed to have a category, so we don't want to permit its change
      return this.oFormFieldTypeId !== "acceptance-criteria";
    },
    title() {
      return this.value.title;
    },
    secondaryTitle: {
      get() {
        if (
          this.value &&
          this.value.configuration &&
          this.value.configuration[this.languageTarget.value + "Title"]
        ) {
          return this.value.configuration[this.languageTarget.value + "Title"];
        }

        return "";
      },
      set(value) {
        this.$emit("input", {
          title: this.title,
          oFormFieldTypeId: this.oFormFieldTypeId,
          oFormFieldCategoryId: this.oFormFieldCategoryId,
          configuration: Object.assign({}, this.configuration, {
            [this.languageTarget.value + "Title"]: value,
          }),
        });
      },
    },
    oFormFieldTypeId: {
      get() {
        return this.value.oFormFieldTypeId;
      },
      set(value) {
        this.$emit("input", {
          title: this.title,
          oFormFieldTypeId: value,
          oFormFieldCategoryId: this.oFormFieldCategoryId,
          configuration: Object.assign(
            {},
            this.configuration,
            {
              [this.languageTarget.value + "Title"]: this.secondaryTitle,
            }
            //{ ...this.getUnfocusedLanguageTitle() }
          ),
        });
      },
    },
    tags() {
      this.tagsIncrement;
      return this.tagsPerQuestion(this.value.id);
    },
    oFormFieldCategoryId: {
      get() {
        return Object.hasOwn(this.value, "oFormFieldCategoryId")
          ? this.value.oFormFieldCategoryId
          : null;
      },
      set(value) {
        this.$emit("input", {
          title: this.title,
          oFormFieldTypeId: this.oFormFieldTypeId,
          oFormFieldCategoryId: value,
          configuration: Object.assign({}, this.configuration, {
            [this.languageTarget.value + "Title"]: this.secondaryTitle,
          }),
        });
      },
    },
    configuration: {
      get() {
        return this.value.configuration;
      },
      set(value) {
        this.$emit("input", {
          title: this.title,
          oFormFieldTypeId: this.oFormFieldTypeId,
          oFormFieldCategoryId: this.oFormFieldCategoryId,
          configuration: Object.assign({}, this.configuration, value, {
            [this.languageTarget.value + "Title"]: this.secondaryTitle,
          }),
        });
      },
    },
  },
  methods: {
    ...mapActions("surveys", ["updateTagMap"]),
    ...mapActions("tags", ["addTag"]),
    ...mapActions("systemLanguages", {
      getLanguages: "getLanguagesList",
    }),
    interpretPendingTags(tags) {
      const promises = [];
      const newTags = [];
      if (this.isAddingTag) {
        return;
      }
      this.isAddingTag = true;
      for (let i = 0; i < tags.length; i++) {
        if (typeof tags[i] === "string") {
          promises.push(this.addTag(tags[i]));
        } else {
          newTags.push(tags[i].value);
        }
      }
      Promise.all(promises).then((results) => {
        for (const result of results) {
          const [success, , data] = result;
          if (success) {
            newTags.push(data.id);
          }
        }

        this.updateTagMap({ questionId: this.value.id, tags: newTags });

        this.tagsIncrement++;
        this.isAddingTag = false;
      });
    },
    getUnfocusedLanguageTitle() {
      const languageTitles = {};

      if (this.value && this.value.configuration) {
        for (const target of this.languagesList) {
          if (
            target.value !== this.languageTarget.value &&
            this.value.configuration[target.value + "Title"]
          ) {
            languageTitles[target.value + "Title"] =
              this.value.configuration[target.value + "Title"];
          }
        }
      }

      return languageTitles;
    },
  },
};
</script>
