<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <validation-provider
          v-slot="{ errors }"
          name="Label"
          rules="required|max:255"
        >
          <v-text-field
            outlined
            v-model="optionLabel"
            :error-messages="errors"
            counter
            maxlength="255"
            hint="The Label"
            label="Option Label"
            required
            aria-required="true"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="3">
        <validation-provider
          v-slot="{ errors }"
          name="value"
          rules="required|max:255"
        >
          <v-text-field
            outlined
            v-model="optionValue"
            :error-messages="errors"
            counter
            maxlength="255"
            hint="The Value"
            label="Option Value"
            required
            aria-required="true"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" md="3" v-if="allowTransitions">
        <validation-provider v-slot="{ errors }" name="transition">
          <v-select
            outlined
            v-model="optionTransition"
            :error-messages="errors"
            :items="surveyPagesList"
            :clearable="true"
            hint="The page a user should be directed to if he/she selects this option"
            label="Page Target"
            aria-required="true"
          ></v-select>
        </validation-provider>
      </v-col>
      <v-col cols="6" md="1" class="d-flex justify-center">
        <v-checkbox
          v-model="optionQualifier"
          class="mx-auto"
          :label="isLessThanMediumBreakpoint ? 'Is Qualifier' : ''"
          :disabled="optionDisqualifier"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="1" class="d-flex justify-center">
        <v-checkbox
          v-model="optionDisqualifier"
          class="mx-auto"
          :label="isLessThanMediumBreakpoint ? 'DNQ' : ''"
          :disabled="optionQualifier"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="6" md="1" class="d-flex justify-center align-start">
        <v-btn
          :icon="!isLessThanMediumBreakpoint"
          @click="deleteOption"
          class="mt-3"
          color="error"
        >
          <v-icon aria-label="Delete Option"> mdi-delete </v-icon>
          <span
            class="d-inline-block d-md-none"
            :aria-hidden="!isLessThanMediumBreakpoint"
            >Delete Option</span
          >
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import { max, required } from "vee-validate/dist/rules.js";

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "QuestionOption",
  components: { ValidationProvider },
  props: {
    value: {
      type: Object,
      required: true,
    },
    // Determine if we want to allow for this option to allow for setting transitions
    allowTransitions: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    allowTransitions(currentAllowance, oldAllowance) {
      // If allowing transitions was originally set to true, which means a transition could've been
      // set, and is now false, don't allow transitions to be set. We don't want to store transitions
      // for options that don't allow them
      if (oldAllowance && !currentAllowance) {
        this.optionTransition = null;
      }
    },
    surveyPagesList() {
      if (
        this.optionTransition &&
        !this.surveyPagesList.some(
          (page) => page.value === this.optionTransition
        )
      ) {
        // If the available page list changes, and the current selected transition is no longer
        // in the page list, set the transition target to null
        this.optionTransition = null;
      }
    },
  },
  computed: {
    ...mapGetters("surveys", ["surveyPagesList", "secondaryLanguageTarget"]),
    ...mapGetters("systemLanguages", ["languageTargets"]),
    isLessThanMediumBreakpoint() {
      return this.$vuetify.breakpoint.sm;
    },
    optionLabel: {
      get() {
        return this.value.label;
      },
      set(value) {
        this.$emit("input", {
          label: value,
          ...this.getUnfocusedLanguageLabels(),
          value: this.optionValue,
          transition: this.optionTransition,
          qualifier: this.optionQualifier,
          disqualifier: this.optionDisqualifier,
        });
      },
    },
    optionValue: {
      get() {
        return this.value.value;
      },
      set(value) {
        this.$emit("input", {
          label: this.optionLabel,
          ...this.getUnfocusedLanguageLabels(),
          value: value,
          transition: this.optionTransition,
          qualifier: this.optionQualifier,
          disqualifier: this.optionDisqualifier,
        });
      },
    },
    optionTransition: {
      get() {
        return Object.hasOwn(this.value, "transition")
          ? this.value.transition
          : null;
      },
      set(value) {
        this.$emit("input", {
          label: this.optionLabel,
          ...this.getUnfocusedLanguageLabels(),
          value: this.optionValue,
          transition: value,
          qualifier: this.optionQualifier,
          disqualifier: this.optionDisqualifier,
        });
      },
    },
    optionQualifier: {
      get() {
        return this.value.qualifier;
      },
      set(value) {
        this.$emit("input", {
          label: this.optionLabel,
          ...this.getUnfocusedLanguageLabels(),
          value: this.optionValue,
          transition: this.optionTransition,
          qualifier: value,
          disqualifier: this.optionDisqualifier,
        });
      },
    },
    optionDisqualifier: {
      get() {
        return this.value.disqualifier;
      },
      set(value) {
        this.$emit("input", {
          label: this.optionLabel,
          ...this.getUnfocusedLanguageLabels(),
          value: this.optionValue,
          transition: this.optionTransition,
          qualifier: this.optionQualifier,
          disqualifier: value,
        });
      },
    },
  },
  methods: {
    deleteOption() {
      this.$emit("delete");
    },
    getUnfocusedLanguageLabels() {
      const languageLabels = {};

      if (this.value) {
        for (const target of this.languageTargets) {
          languageLabels[target.value + "Label"] =
            this.value[target.value + "Label"];
        }
      }

      return languageLabels;
    },
  },
};
</script>

<style scoped></style>
