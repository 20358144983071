<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <validation-provider v-slot="{ errors }" name="Terms" rules="required">
          <v-textarea
            outlined
            v-model="terms"
            :error-messages="errors"
            counter
            hint="Terms & Conditions for acceptance"
            label="Terms"
            aria-required="true"
          ></v-textarea>
        </validation-provider>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  name: "QuestionAcceptanceCriteria",
  components: { ValidationProvider },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("surveys", ["secondaryLanguageTarget"]),
    ...mapGetters("systemLanguages", ["languageTargets"]),
    terms: {
      get() {
        if (this.value && this.value.terms) {
          return this.value.terms;
        }

        return "";
      },
      set(value) {
        if (this.secondaryLanguageTarget !== undefined) {
          this.$emit("input", {
            terms: value,
            [this.secondaryLanguageTarget.value + "Terms"]: this.secondaryTerms,
            ...this.getUnfocusedLanguageTerms(),
          });
        } else {
          this.$emit("input", {
            terms: value,
            ...this.getUnfocusedLanguageTerms(),
          });
        }
      },
    },
    secondaryTerms: {
      get() {
        if (
          this.value &&
          this.value[this.secondaryLanguageTarget.value + "Terms"]
        ) {
          return this.value[this.secondaryLanguageTarget.value + "Terms"];
        }

        return "";
      },
      set(value) {
        this.$emit("input", {
          terms: this.terms,
          [this.secondaryLanguageTarget.value + "Terms"]: value,
          ...this.getUnfocusedLanguageTerms(),
        });
      },
    },
  },
  methods: {
    getUnfocusedLanguageTerms() {
      const languageTerms = {};

      if (this.value && this.secondaryLanguageTarget !== undefined) {
        for (const target of this.languageTargets) {
          if (
            target.value !== this.secondaryLanguageTarget.value &&
            this.value[target.value + "Terms"]
          ) {
            languageTerms[target.value + "Terms"] =
              this.value[target.value + "Terms"];
          }
        }
      }

      return languageTerms;
    },
  },
};
</script>
