<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <p>Default Terms:<br />{{ terms }}</p>
        <validation-provider
          v-slot="{ errors }"
          :name="languageTarget.text + ' Terms'"
          rules="required"
        >
          <v-textarea
            outlined
            v-model="secondaryTerms"
            :error-messages="errors"
            counter
            :hint="languageTarget.text + ' Terms & Conditions for acceptance'"
            :label="languageTarget.text + ' Terms'"
            required
            aria-required="true"
          ></v-textarea>
        </validation-provider>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "SecondaryQuestionAcceptanceCriteria",
  components: { ValidationProvider },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    languageTarget: {
      type: Object,
      required: true,
    },
  },
  computed: {
    terms() {
      if (this.value && this.value.terms) {
        return this.value.terms;
      }

      return "";
    },
    secondaryTerms: {
      get() {
        if (this.value && this.value[this.languageTarget.value + "Terms"]) {
          return this.value[this.languageTarget.value + "Terms"];
        }

        return "";
      },
      set(value) {
        this.$emit("input", {
          [this.languageTarget.value + "Terms"]: value,
        });
      },
    },
  },
};
</script>
